import React from 'react'
import './portfolio.css'
import FlorentEspana from '../../images/FlorentEspana.png'
import EightyOne from '../../images/EightyOne.png'
import Concrete from '../../images/Concrete.png'
import ClubAuto from '../../images/ClubAuto.png'
import Easyloc from '../../images/Easyloc.png'


const Portfolio = () => {
    return (
        <section className='portfolio'>
            <p className='portFolioTitle text-center'>Port<span className='competences'>folio</span></p>

            <div class="portFolio-container">
                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://www.florent-espana.com" target="_blank" rel="noopener noreferrer">
                        Florent Espana</a>
                    <div className='portFolio-content'>
                        <a href="https://www.florent-espana.com" target="_blank" rel="noopener noreferrer">
                            <img src={FlorentEspana} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Réalisation d'un site web vitrine et de e-commerce pour l'artiste peintre Florent
                                Espana. Création d'une plateforme permettant à l'artiste de présenter ses
                                oeuvres et de vendre ses peintures en ligne.</p>
                            <p className='techno'>- Wordpress, WooCommerce, PHP et MySQL</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://eightyonestore.com/" target="_blank" rel="noopener noreferrer">
                        Eighty One Store</a>
                    <div className='portFolio-content'>
                        <a href="https://eightyonestore.com/" target="_blank" rel="noopener noreferrer">
                            <img src={EightyOne} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Gestion et maintenance du site e-commerce 81 Store, boutique spécialisée dans la vente de produits pour le graffiti et les arts graphiques.</p>
                            <p className='techno'>- Wordpress, WooCommerce, PHP et MySQL</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="http://concretejunglefestival.infinityfreeapp.com/" target="_blank" rel="noopener noreferrer">
                        Concrete Jungle</a>
                    <div className='portFolio-content'>
                        <a href="http://concretejunglefestival.infinityfreeapp.com/" target="_blank" rel="noopener noreferrer">
                            <img src={Concrete} alt='site de concrete jungle festival' className='concreteImg' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Projet réalisé dans le cadre de la formation.
                                Création d'une application web mobile headless pour la société fictive
                                d'évènements "Live Events" à l'occasion du festival de musique Concrete
                                Jungle Festival. Développement du frontend avec React et du backend avec
                                Wordpress.</p>
                            <p className='techno'>- React, HTML, CSS, Wordpress, WooCommerce, PHP et MySQL</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://arnaudm1983.github.io/ClubAutoBootstrap/" target="_blank" rel="noopener noreferrer">
                        Club Auto</a>
                    <div className='portFolio-content'>
                        <a href="https://arnaudm1983.github.io/ClubAutoBootstrap/" target="_blank" rel="noopener noreferrer">
                            <img src={ClubAuto} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Site web vitrine réalisé pour Club Auto, une entreprise fictive spécialisée dans
                                la vente et la location de voitures. Création d’une interface moderne et
                                responsive, mettant en valeur les services et produits de l'entreprise.
                                Développement démontrant la capacité à intégrer des designs tout en assurant
                                une expérience utilisateur fluide.</p>
                            <p className='techno'>- HTML, CSS, Bootstrap</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://github.com/ArnaudM1983/easyloc" target="_blank" rel="noopener noreferrer">
                        Easyloc'</a>
                    <div className='portFolio-content'>
                        <a href="https://github.com/ArnaudM1983/easyloc" target="_blank" rel="noopener noreferrer">
                            <img src={Easyloc} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Projet réalisé dans le cadre de la formation. Développement d'une bibliothèque d'accès aux données pour EasyLoc', société fictive de location de voitures. Assurant documentation, sécurité et tests unitaires. Utilisation de Symfony pour faciliter l'interaction avec les bases SQL et NoSQL.</p>
                            <p className='techno'>- Symfony, PHP, MySQL</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Portfolio