import React from 'react'
import './Footer.css'
import github from '../../images/github.png';
import linkedin from '../../images/linkedin.png'

const Footer = () => {
  return (
    <div className='footer'>
      <>
        <footer className="container-fluid pt-3">
          <div className="row">
            <div className="col-12  reseaux-sociaux d-flex align-items-center justify-content-center pb-3">
              <a className='social-img' href="https://github.com/ArnaudM1983" target="_blank" rel="noopener noreferrer">
                <img src={github} alt='logo github' /></a>
              <a className='social-img' href="https://www.linkedin.com/in/arnaudmeusy/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt='logo linkedin' /></a>
            </div>
            <p className='copyright text-center'>© 2024 - Arnaud Meusy</p>
          </div>
        </footer>

      </>
    </div>
  )
}

export default Footer