import React from 'react'
import './about.css'
import profil from '../../images/profil.png'

const About = () => {
  return (
    <div className='text-center aboutContainer'>
    <p className='about text-center'>À propos de <span className='moi'>moi</span></p>
    <img src={profil} alt='profil' className='profileImage' />
    <p className='aboutText'>Bonjour ! Je m'appelle Arnaud Meusy, développeur web en formation.</p>
    <p className='aboutText'>Je suis actuellement en reconversion professionnelle pour embrasser une carrière dans le domaine du développement web. Après avoir acquis une expérience solide en tant que manager dans la grande distribution chez Carrefour, j'ai décidé de suivre une formation intensive au sein de l'EPSI de Lyon.</p>
    <p className='aboutText'>Dans le cadre de ma formation, je suis actuellement à la recherche d'un stage de fin de formation. Ce stage est crucial pour l'obtention du titre professionnel 31114 de niveau 5 Développeur web et web mobile. Je suis enthousiaste à l'idée de mettre en pratique mes connaissances acquises et de contribuer à des projets concrets au sein d'une entreprise dynamique.</p>
    </div>
  )
}

export default About