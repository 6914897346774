import React from 'react'
import { useState } from 'react'
import './navbar.css'
import { Link } from 'react-scroll'
import github from '../../images/github.png';
import linkedin from '../../images/linkedin.png'
import menu from '../../images/menu.png';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <nav className='navbar'>
                
                <p className='logo mb-0'><span className='prenom'>Arnaud</span><span className='nom'>Meusy</span></p>
            
            <div className='desktopMenu'>
                <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={100} className='desktopMenuListItem'>Accueil</Link>
                <Link activeClass='active' to='about' spy={true} smooth={true} offset={-100} duration={100} className='desktopMenuListItem'>Profil</Link>
                <Link activeClass='active' to='competences' spy={true} smooth={true} offset={-100} duration={100} className='desktopMenuListItem'>Compétences</Link>
                <Link activeClass='active' to='portfolio' spy={true} smooth={true} offset={-75} duration={100} className='desktopMenuListItem'>Portfolio</Link>
                <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-40} duration={100} className='desktopMenuListItem'>Contact</Link>
            </div>
            <div className='socialMedia'>
                <a href="https://github.com/ArnaudM1983" target="_blank" rel="noopener noreferrer">
                <img src={github} alt='logo github'/></a>
                <a href="https://www.linkedin.com/in/arnaudmeusy/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt='logo linkedin'/></a>
            </div>

            <img src={menu} alt='menu' className='mobMenu' onClick={()=>setShowMenu(!showMenu)}></img>
            <div className='navMenu' style={{display: showMenu? 'flex':'none'}}>
                <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={100} className='ListItem' onClick={()=>setShowMenu(false)}>Accueil</Link>
                <Link activeClass='active' to='about' spy={true} smooth={true} offset={-100} duration={100} className='ListItem' onClick={()=>setShowMenu(false)}>Profil</Link>
                <Link activeClass='active' to='competences' spy={true} smooth={true} offset={-100} duration={100} className='ListItem' onClick={()=>setShowMenu(false)}>Compétences</Link>
                <Link activeClass='active' to='portfolio' spy={true} smooth={true} offset={-75} duration={100} className='ListItem' onClick={()=>setShowMenu(false)}>Portfolio</Link>
                <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-68} duration={100} className='ListItem' onClick={()=>setShowMenu(false)}>Contact</Link>
            </div>
            
    
        </nav>
    )
}

export default Navbar;
