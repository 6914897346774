import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/NavBar/navbar';
import Intro from './components/Intro/intro';
import './style.css';
import About from './components/About/about';
import Competences from './components/Competences/competences';
import Portfolio from './components/Portfolio/portfolio'
import Contact from './components/Contact/contact';
import Footer from './components/Footer/Footer';

function App() {
  return (
     <div  className="App">
      <Navbar />
      <Intro />
      <About />
      <Competences/>
      <Portfolio/>
      <Contact/>
      <Footer/>
     </div>
  );
}

export default App;
