import React, { useRef } from 'react'
import './contact.css'
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_kn45rnn', 'template_j22tpy7', form.current, {
        publicKey: 'x7Ec7giCQPSqh20_D',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          e.target.reset();
          alert ('Email envoyé !');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <div id='contact' className='text-center contact'>
      <p className='contactTitle'>Contactez<span className='competences'>-moi</span></p>
      <div className='contactDesc'>N'hésitez pas à me contacter pour en savoir plus</div>
      <form className='contactForm' ref={form} onSubmit={sendEmail}>
        <input type='text' className='nameForm' placeholder='Votre nom' name='your_name'></input>
        <input type='email' className='email' placeholder='Votre mail' name='your_email'></input>
        <textarea className='msg' name='message' rows="5" placeholder='Votre message'></textarea>
        <button type='submit' value='Send' className='btnContact'>Envoyer</button>
      </form>
    </div>
  )
}

export default Contact